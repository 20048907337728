@import "../../assets/sass/fonts";
@import "../../assets/sass/colors";

html, body {
    font-family: $font-family;
    font-size: $fontsize;
    letter-spacing: $fontspacing;
    line-height: $lineheight;
    color: $foreground;

    user-select: none;
    text-shadow: $foreground-glow;

    height: 100%;
    margin: 0;
}

body {
    margin: 0;
    background: $background;
    background-image: $background-gradient;
    background-attachment: fixed;
    height: 100%;
}

.phosphor {
    padding: $lineheight;
}

.alert {
    color: $alert;
    background: $foreground;
    text-shadow: $background-glow;
    box-shadow: $foreground-glow;
    animation: flash 1.33s infinite steps(1);

    @keyframes flash {
        0% {
            color: $light-background;
            background: $foreground;
            text-shadow: $background-glow;
            box-shadow: $box-foreground-glow;
        }
        50% {
            color: $alert;
            text-shadow: $foreground-glow;
            background: $transparent;
            box-shadow: none;
        }
    }
}

.ascii_art {
    line-height: $fontsize;
    padding: $fontsize;
}