@import "../../assets/sass/fonts";
@import "../../assets/sass/colors";

.__modal__ {
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    background: rgba($background, 0.8);
    color: $foreground;

    .content {
        position: fixed;
        top: 50%;
        left: 50%;
        padding: $lineheight * 0.5;
        transform: translate(-50%, -50%);
        background: $foreground;
        color: $light-background;
        text-shadow: $background-glow;
        box-shadow: $box-foreground-glow;

        p {
            margin: 0;
        }
    }
}

body.static {
    height: 100vh;
    overflow-y: hidden;
}