@import "../../assets/sass/fonts";
@import "../../assets/sass/colors";

.__link__ {
    & {
        cursor: pointer;
        display: block;
    }
    &:hover,
    &:active {
        color: $light-background;
        background: $foreground;
        text-shadow: $background-glow;
        box-shadow: $box-foreground-glow;
    }

    &:hover {
        opacity: 1;
    }

    &:active {
        opacity: 0.5;
        transition: opacity 50ms;
    }
}