@import "../../assets/sass/colors";

.__teletype__ {
    white-space: pre;

    .cursor {
        color: rgba($background, 0.2);
        background: $foreground;
    }

    .hidden {
        visibility: hidden;
    }
}