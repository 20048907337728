// default: black bg, blue/white fg

// TODO: add theme mixins
// blue/white
// $foreground: hsl(211, 79%, 73%);//rgb(212, 249, 250);
// $background: rgb(0, 12, 12);
// $alert: rgb(255, 60, 0);

// amber
// $foreground: rgb(224, 125, 11);
// $light-background: hsv(224, 125, 11); //rgb(36, 161, 20);
// $background: rgb(8, 4, 0);
// $alert: rgb(255, 60, 0);

// green
$foreground: rgb(12, 204, 104); //rgb(36, 161, 20);
$light-background: rgb(6, 85, 43); //rgb(36, 161, 20); 
$background: rgb(0, 2, 0);
$alert: rgb(12, 204, 104);

// white
// $foreground: rgb(218, 218, 218);
// $background: rgb(2, 2, 2);
// $alert: rgb(255, 60, 0);

$scanlines-foreground: rgba($foreground, 0.05);
$scanlines-background: rgba($background, 0.5);

$transparent: rgba($background, 0);

$foreground-glow: 0 0 5px rgba($foreground, 0.8), 0 0 10px rgba($foreground, 0.6), 0 0 25px rgba($foreground, 0.4), 0 0 50px rgba($foreground, 0.2);
$subtle-foreground-glow: 0 0 5px rgba($foreground, 0.75);
$box-foreground-glow: 0 0 10px rgba($foreground, 0.75), 0 0 25px rgba($foreground, 0.5), 0 0 50px rgba($foreground, 0.3), 0 0 100px rgba($foreground, 0.1);
$background-glow: 0 0 2px rgba($background, 0.5);
$alert-glow: 0 0 5px rgba($alert, 0.75), 0 0 10px rgba($alert, 0.5), 0 0 25px rgba($alert, 0.3), 0 0 50px rgba($alert, 0.1);

$background-gradient: radial-gradient(rgba($foreground, 0.05), rgba($background, 1) 100%)